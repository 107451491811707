.signup {

    form {
        max-width: 600px;
        margin: 0 auto;
        position: relative;
    }

    label {
        display: block;
        font-size: 18px;
        margin-bottom: 4px;
    }

    input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 5px;
        font-size: 18px;
        margin-bottom: 16px;
    }

    p.error {
        color: var(--danger);
        margin-top: -8px;
    }

    p.error::before {
        display: inline;
        content: "⚠ ";
    }

    .passwordStrengthContainer {
        margin-bottom: 15px;
    }

    .togglePass {
        color: var(--fgSoft);
        font-size: 20px;
        position: absolute;
        right: 8px;
        margin-top: -42px;
        cursor: pointer;
    }
}
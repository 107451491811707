.post {
    margin-bottom: 64px;
}

:global(.searchResults) .post {
  margin-bottom: 32px;
}

:global(.internalLinkExpand) .post {
  margin-bottom: 12px;
}

.title, .title a {
    color: var(--fgHard);
    font-size: 24px;
    margin-bottom: 10px;
}

input.title {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    font-size: 24px;
    margin-bottom: 16px;
}

.title a:hover {
    color: var(--fgHarder);
}

.content {
    font-size: 16px;
    @media only screen and (max-width: 600px) { font-size: 14px; }
    line-height: 1.5em;
    color: var(--fg);
    overflow-wrap: anywhere;

    img {
        max-width: 720px;
        max-height: 500px;
    }

    :global(.react-transform-wrapper) img {
      max-width: unset;
      max-height: unset;
    }

    // TODO video sizing
}

.controls {
    display: flex;
    margin-top: 8px;
    margin-left: -4px;
    align-items: center;
    overflow-x: visible;
}

.control {
    margin-right: 30px;
    display: flex;
    align-items: center;
    color: var(--fgSoftest);

    button, >a {
        height: 24px;
        min-width: 24px;
        background: none;
        margin: 0;
        padding: 0;
        border: none;

        display: flex;
        align-items: center;
        font-size: 14px;

        fill: var(--fgSoftest);
        color: var(--fgSoftest);

        text-decoration: none;
        font-weight: bold;

        &.active {
            fill: var(--primary);
            color: var(--primary);
            font-weight: bold;
            .label{
                color: var(--primary);
            }
        }
        &:hover {
            color: var(--primaryHover);
            fill: var(--primaryHover);
        }
        &:disabled {
            color: var(--fgGhost);
            fill: var(--fgGhost);
            cursor: default;
        }
    }

    .label {
        color: var(--fgSofter);
        margin-left: 5px;
    }

    @media only screen and (max-width: 420px) {
        .noComments{
            display: none;
        }
    }

}

.optionsList {
  position: absolute;
  left: 30px;
  top: -4px;
  border: 1px solid var(--disabled-fg);
  border-radius: 4px;
  padding: 4px;
  width: max-content;
  background-color: var(--bg);
  z-index: 99999;

    > button {
        margin: 12px 8px !important;
    }
}
.options {
    position: relative;
}
.optionsList .label {
    margin-right: 4px;
}

.signature {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 8px;
  color: var(--fgSoftest);
  >a {
    text-decoration: none;
    color: var(--fgSoftest);
    &:hover {
      color: var(--fgSofter);
    }
  }

  :global{
    .arrow{
      display: flex;
      align-items: center;
      fill: var(--fgSoftest)
    }
  }

  .username {
    color: var(--fgSoftest);
    &:before {
      color: var(--fgSoftest);
    }
    &:hover {
      color: var(--primary);
    }
  }

  .toggleHistory {
    border: none;
    background: none;
    font-size: 13px;
    margin: 0;
    padding: 0;
    color: var(--fgSoftest);
    font-family: 'Roboto', sans-serif;
  }
}

@font-face {
	font-family: "icons";
	font-display: block;
	src: url("//orbitar.space/static/font/icons.281b6d862eb2d477bba75eaef5df8c44.eot?#iefix") format("embedded-opentype"),
url("//orbitar.space/static/font/icons.6c1c45b9fd4a7c8e1b2b3d0c94a604ef.woff") format("woff"),
url("//orbitar.space/static/font/icons.35354fbd10181f064d07ce652c60ea85.woff2") format("woff2"),
url("//orbitar.space/static/font/icons.ee8521a7ed7144c7643cc57ffb9bba84.ttf") format("truetype"),
url("//orbitar.space/static/font/icons.42a7d27e7204e7a35f503504d8bf938a.svg#icons") format("svg");
}

.i {
	line-height: 1;
}

.i:before {
	font-family: icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.i-alive:before {
	content: "\f101";
}
.i-alttranslate:before {
	content: "\f102";
}
.i-annotate:before {
	content: "\f103";
}
.i-anon:before {
	content: "\f104";
}
.i-arrow:before {
	content: "\f105";
}
.i-best:before {
	content: "\f106";
}
.i-bookmark:before {
	content: "\f107";
}
.i-broken-heart:before {
	content: "\f108";
}
.i-can-not-invite:before {
	content: "\f109";
}
.i-close:before {
	content: "\f10a";
}
.i-comments:before {
	content: "\f10b";
}
.i-coub:before {
	content: "\f10c";
}
.i-dead:before {
	content: "\f10d";
}
.i-edit:before {
	content: "\f10e";
}
.i-expand:before {
	content: "\f10f";
}
.i-eye:before {
	content: "\f110";
}
.i-ghost:before {
	content: "\f111";
}
.i-hide:before {
	content: "\f112";
}
.i-hot:before {
	content: "\f113";
}
.i-image:before {
	content: "\f114";
}
.i-info:before {
	content: "\f115";
}
.i-irony:before {
	content: "\f116";
}
.i-link:before {
	content: "\f117";
}
.i-live:before {
	content: "\f118";
}
.i-logout:before {
	content: "\f119";
}
.i-mail-open:before {
	content: "\f11a";
}
.i-mail-secure:before {
	content: "\f11b";
}
.i-mailbox-secure:before {
	content: "\f11c";
}
.i-monster_large_ny:before {
	content: "\f11d";
}
.i-monster_large:before {
	content: "\f11e";
}
.i-monster:before {
	content: "\f11f";
}
.i-new:before {
	content: "\f120";
}
.i-no-edit:before {
	content: "\f121";
}
.i-no-poop:before {
	content: "\f122";
}
.i-no-posts:before {
	content: "\f123";
}
.i-notification:before {
	content: "\f124";
}
.i-options:before {
	content: "\f125";
}
.i-play:before {
	content: "\f126";
}
.i-post:before {
	content: "\f127";
}
.i-profile:before {
	content: "\f128";
}
.i-quote:before {
	content: "\f129";
}
.i-rating_minus:before {
	content: "\f12a";
}
.i-rating_plus:before {
	content: "\f12b";
}
.i-search:before {
	content: "\f12c";
}
.i-send:before {
	content: "\f12d";
}
.i-slow:before {
	content: "\f12e";
}
.i-spoiler-mask:before {
	content: "\f12f";
}
.i-spoiler:before {
	content: "\f130";
}
.i-theme_dark:before {
	content: "\f131";
}
.i-theme_light:before {
	content: "\f132";
}
.i-thumbs-up:before {
	content: "\f133";
}
.i-translate:before {
	content: "\f134";
}
.i-unwatch:before {
	content: "\f135";
}
.i-user:before {
	content: "\f136";
}
.i-vimeo:before {
	content: "\f137";
}
.i-watch:before {
	content: "\f138";
}
.i-youtube:before {
	content: "\f139";
}

.content {
  max-height: fit-content;

  :global(iframe, video) {
      max-width: 100%;
      aspect-ratio: 16 / 9;
      height: auto;
  }

  :global(iframe, video):not(.coub-embed) {
      aspect-ratio: 16 / 9;
      height: auto;
  }

  &.cut {
    overflow: hidden;
    -webkit-mask-image: -webkit-gradient(linear, left 70%, left 90%, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to top, rgba(0,0,0,0) 50px, rgba(0,0,0,1) 100px);
  }

  b, i, u, strike, blockquote{
    color: var(--fg);
  }

  img, :global(a.youtube-embed) img, :global(a.video-embed) img, :global(a.coub-embed) img, :global(a.vimeo-embed) img {
    max-width: 100%;
    max-height: 500px;
  }

  :global(a.youtube-embed), :global(a.video-embed), :global(a.coub-embed), :global(a.vimeo-embed) {
    position: relative;
    display: inline-block;

    &:after {
      display: block;

      content: '';
      background-size: 100% 100%;
      background: url(../Assets/youtube.svg) no-repeat center center;

      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.8;
      pointer-events: none;
      cursor: pointer;
    }
    &:hover:after {
      opacity: 1;
    }
  }

  :global(a.video-embed) {

    &:after {
      background: url(../Assets/play.svg) no-repeat center center;

      opacity: 0.8;
    }
    &:hover:after {
      opacity: 1;
    }
  }

  :global(a.coub-embed) {

    &:after {
      background: url(../Assets/coub.svg) no-repeat center center;
      opacity: 0.8;
    }
    &:hover:after {
      opacity: 1;
    }
  }

  :global(a.vimeo-embed) {

    &:after {
      background: url(../Assets/vimeo.svg) no-repeat center center;

      opacity: 0.8;
    }
    &:hover:after {
      opacity: 1;
    }
  }

  :global(.interpretation) {
    color: var(--fgGhost);

    :global(.i):before {
      vertical-align: middle;
      font-size: 16px;
      padding-right: 4px;
    }
  }

  :global(.secret-mailbox), :global(.secret-mail) {
    border: 1px solid var(--dim3);
    background-color: var(--dim2);
    color: var(--fgMedium);
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    max-width: 100%;
    display: block;
    vertical-align: middle;

    &:global(.secret-mail-disabled), &:global(.secret-mail-error) {
      cursor: default;
      opacity: 0.5;
    }
    &:global(.secret-mail-decoded), &:global(.secret-mail-decoding) {
      cursor: default;
    }
    &:global(.secret-mail-decoded) {
      white-space: pre-wrap;
    }

    &:global(.secret-mail-error) {
      background-color: var(--dangerGhost);
    }

    &:hover:not(:global(.secret-mail-disabled), :global(.secret-mail-error), :global(.secret-mail-decoded),
      :global(.secret-mail-decoding)) {
      color: var(--primaryHover);
      background-color: var(--dim3);
      border-color: var(--primaryHover);
    }

    &:global(.i):before {
      vertical-align: middle;
      font-size: 26px;
      padding-right: 4px;
    }
  }

  :global(.expand-button) {
    display: inline-block;
    padding: 0 6px 1px 2px;
    font-size: 22px;
    cursor: pointer;
    color: var(--fgMedium);
    vertical-align: middle;
    user-select: none;

    transform: rotate(0deg);
    transition: transform .25s;
    transform-origin: 40% center; // Add this line

    &:hover {
      color: var(--primaryHover);
    }

    &:global(.expanded) {
      transform: rotate(90deg);
      transition: transform .25s;
    }
  }
}

.overlayCloseButton {
  position: absolute;
  top: 35px;
  right: 30px;
  font-size: 40px;
  cursor: pointer;
  text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 2px #000, -1px -1px 2px #000;
}

.cutCover {
  position: relative;
}

.cutButton {
  position: absolute;
  bottom: 8px;
  left: 0;
  background: var(--dim2);
  color: var(--fgMedium);
  border: none;
  //border: 1px solid var(--dim3);
  padding: 8px;
  border-radius: 4px;
  font-size: 15px;
  &:hover{
    background: var(--dim3);
  }
}

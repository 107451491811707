.resetPassword{
  box-sizing: border-box;
  min-height: 100%;
  padding: 60px 0 60px;
  width: 600px;
  max-width: 80vw;
  margin: 0 auto;
  position: relative;

  form, h2, h3, .resetSent, .resetLink {
    max-width: 600px;
    margin: auto;
  }

  h2, h3 {
    margin: 10px auto;
  }

  form {
    margin-bottom: 60px;
  }

  .resetInfo {
    color: gray;
    margin-bottom: 10px;
  }

  .resetSent {
    padding-bottom: 60px;
  }

  .resetLink {
    text-align: right;
    margin-bottom: 20px;
  }

  //label {
  //  display: block;
  //  font-size: 18px;
  //  margin-bottom: 4px;
  //}

  input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    font-size: 18px !important;
    margin-bottom: 16px;
  }

  p.error {
    color: var(--danger);
  }

  p.error::before {
    display: inline;
    content: "⚠ ";
  }

  .passwordStrengthContainer {
    margin-bottom: 15px;
  }

  .weakPasswordConfirmationContainer {
    margin-bottom: 16px;
  }

  .togglePass {
    color: var(--fgSoft);
    font-size: 20px;
    position: absolute;
    right: 8px;
    margin-top: -42px;
    cursor: pointer;
  }

}


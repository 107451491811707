#karmaCalculator{
  .slider{
    display: block;
    margin-top: 20px;
    input{
      width: 350px;
    }
  }
}
#karmaCalculator label div {
  color: var(--fgSoftest);
  max-width: 350px;
}
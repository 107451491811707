@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

// TODO add css reset

html {
  overflow-y: scroll;
}

html,body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--bg);
  color: var(--fg);
}
#root {
  height: 100%;
}

input[type="text"], input[type="password"], input[type="email"], input[type="search"], textarea {
  background: var(--lowered);
  box-shadow: inset 0 0 2px var(--shadow);
  border: none;
  border-radius: 4px;
  color: var(--fg);
  font-family: 'Roboto', sans-serif;
  outline: none;
  resize: vertical;
}

input[type="submit"], button {
  cursor: pointer;
}

input[type="submit"], .button {
  font-family: 'Roboto', sans-serif;
  padding: 8px 16px;
  background: var(--primary);
  border: none;
  color: var(--onAccent);
  border-radius: 4px;
  font-weight: bold;

  &:hover{
    background: var(--primary);
  }
  &:disabled{
    background: var(--primaryGhost);
    color: var(--onAccentGhost);
    cursor: default;
  }
}

a {
  color: var(--link);
  text-decoration: none;
  &:hover {
    color: var(--linkHover);
  }
}

.irony {
  font-style: italic;
  color: var(--danger);
}

svg#spoiler-mask {
  /* hide svg */
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  /* Firefox fix */
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.spoiler {
  background-color: var(--fg);
  filter: blur(2px);
  opacity: .8;
  /* more blur to img video etc */
  & > * {
    filter: url('#spoiler-mask-filter');
  }

  &:hover {
    background-color: inherit;
    filter: none;
    opacity: 1;
    & > * {
      filter: none;
    }
  }
}

.mention:before {
  content: '@';
  color: transparent;
  background: url('./Assets/user.svg') no-repeat -1px 2px;
  background-size: 16px 16px;
}

details.expand {
  background-color: var(--dim1);
  border-radius: 4px;
  padding: 0.5em;
  margin: 0.5em 0;

  & > summary {
    display: block;
    position: relative;
    padding-left: 1.5em;
    color: var(--fg);
    font-weight: bold;
    list-style-type: none;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    &::-moz-list-bullet{
      list-style-type:none;
    }

    &::marker{
      display:none;
    }

    &::before {
      left: 0;
      top: 0;
      content: '';
      -webkit-mask: url('./Assets/expand.svg') no-repeat 50% 50%;
      mask: url('./Assets/expand.svg') no-repeat 50% 50%;
      background-color: var(--button-dis-fg);
      width: 20px;
      height: 20px;
      position: absolute;
      transition: transform .25s;
      user-select: none;
    }
  }

  &[open] > summary {
    margin-bottom: .5em;
  }

  &[open] > summary::before {
    transform: rotateZ(90deg);
  }

  & > div[role="button"] {
    display: block;
    background-color: var(--dim1);
    height: 0.5em;
    cursor: pointer;
  }
}

blockquote {
  border-radius: 4px;
  padding: 0.5em 0.5em 0.5em 2em;
  margin: 0.5em 0;
  background-color: var(--dim1);
  position: relative;

  &::before {//TODO replace icon with font

    /* somewhat hacky implementation for icon color, source: https://stackoverflow.com/a/42317014/1349366 */
    content: '';
    -webkit-mask: url('./Assets/quote.svg') no-repeat 50% 50%;
    mask: url('./Assets/quote.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: var(--button-dis-fg);

    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    left: 8px;
    opacity: 0.5;
  }
}

.bold {
  font-weight: bold;
}

img.image-scalable {
  cursor: zoom-in;
  max-width: 100%;
  max-height: 500px;
}
img.image-preview {
  max-width: 100%;
  max-height: none;
  cursor: zoom-out;
}

video {
  max-width: 100%;
}

img.image-large {
  display: block;
}
img.low-rating, video.low-rating, iframe.low-rating {
  opacity: 0.5;
  /*blur*/
  filter: blur(2px);
  -webkit-filter: blur(2px);
  max-height: 48px;
  max-width: 48px;
}

html.no-scroll {
  overflow: hidden;
  margin-right: 16px;
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 12px 12px var(--fgAlmostInvisible);
  border-radius: 10px;
  border: solid 3px transparent;
  &:hover{
    box-shadow: inset 0 0 12px 12px var(--fgGhost);
  }
}


::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 14px 14px var(--fgGhost);
  &:hover{
    box-shadow: inset 0 0 14px 14px var(--fgSoftest);
  }
}

.react-confirm-alert-overlay.orbitar-confirm-overlay {
  z-index: 200;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  backdrop-filter: blur(5px);
  background-size:cover;

  .react-confirm-alert-body {
    background-color: var(--elevated);
    color: var(--fg);

    button {
      background: var(--danger);
      border: none;
      border-radius: 4px;
      padding: 8px 16px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        background: var(--dangerHover);
      }
      &:disabled {
        background: var(--primaryGhost);
        color: var(--onAccentGhost);
        cursor: default;
      }
      &.cancel {
        background: var(--fgSoftest);
        &:hover {
          background: var(--fgSofter);
        }
      }
    }
  }
}
.paginator {

}

.ellipsis, .page {
  border-radius: 4px;
  padding: 6px;
  @media only screen and (max-width: 600px) { padding: 2px; }

  min-width: 20px;
  display: inline-block;
  text-align: center;
  margin: 0 5px;
  text-decoration: none;
}

.page {
    color: var(--fgSofter);
}

.page.current, .page:hover {
    color: var(--primary);
    font-weight: bold;
}

.page.disabled {
    pointer-events: none;
}

.page.control.disabled {
    opacity: 0.5;
}

.ellipsis {
  color: var(--fgSoftest);
  cursor: default;
}
.wrapper{
  box-sizing: border-box;
  min-height: 100%;
  padding: 20px 0 60px;
  width: 600px;
  max-width: 80vw;
  margin: 0 auto;

  .info{
    padding-bottom: 80px;
  }
}

.container {
  display: flex;
  flex-direction: row;
}

div.info {
  display: flex;
  flex-direction: row;
  flex-wrap: unset;
  column-gap: 20px;

  margin-bottom: 12px;

  > p {
    margin: 16px 0;
    line-height: 1.4em;
  }
}

.trialProgress {
  width: 350px;
}

:global(.CircularProgressbar .CircularProgressbar-path) {
  stroke: var(--primary);
}

.negativeTrialProgress {
  :global(.CircularProgressbar .CircularProgressbar-path) {
    stroke: var(--danger);
  }
}

:global(.CircularProgressbar .CircularProgressbar-trail) {
  stroke: var(--dim3);
}

:global(.CircularProgressbar .CircularProgressbar-text) {
  fill: var(--fg);
  font-size: 160%;
  dominant-baseline: middle;
  text-anchor: middle;
}

.beta {
  display: inline-block;
  color: var(--bg);
  font-size: 12px;
  font-weight: 600;
  background: var(--primary);
  padding: 2px 4px;
  border-radius: 2px;
  margin-bottom: 5px;
}

.restricted {
  width: 100%;
  border-radius: 4px;
  padding: 20px 16px 20px 24px;
  color: var(--fg);
  background-color: var(--dim3);
  margin-bottom: 6px;
  display: flex;
  gap: 24px;
  align-items: center;

  > span {
    font-size: 24px;
  }

  p {
    margin: 4px 0 0;
    font-size: 0.8em;
    color: var(--fgSoft);

    ul.supporters {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0;
      gap: 4px;
      margin-top: 5px;
    }
  }

}

.subsiteList {
  background: var(--elevated);
  box-shadow: 0 0 4px var(--shadow);
  border-radius: 8px;
  border-right: 1px solid var(--bg);
  padding: 10px;
}